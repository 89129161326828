import React from "react";

import { HiOutlineViewBoards } from "react-icons/hi";
import { LuClipboardSignature } from "react-icons/lu";
import { CiDeliveryTruck } from "react-icons/ci";
import { PiHandshakeLight } from "react-icons/pi";

export default function InfoContent() {
  return (
    <div>
      <div className="info-content wrapper">
        <div data-aos="zoom-in">
          <HiOutlineViewBoards />
          <span>izrada unikatnih dasaka</span>
        </div>
        <div data-aos="zoom-in">
          <LuClipboardSignature />
          <span>Gravura po zelji</span>
        </div>
        <div data-aos="zoom-in">
          <CiDeliveryTruck />
          <span>Slanje sirom Srbije i BiH</span>
        </div>
        <div data-aos="zoom-in">
          <PiHandshakeLight />
          <span>Licno preuzimanje u Zvorniku</span>
        </div>
      </div>
    </div>
  );
}
