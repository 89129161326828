import React, { useState } from "react";

import galleryHeader from "../../assets/images/contact.jpeg";
import aboutContent from "../../assets/images/about.jpeg";
import videoFile from "../../assets/videos/video.mov";
import bagremovo from "../../assets/images/bagremovo.png";
import orahovo from "../../assets/images/orahovo.png";
import hrastovo from "../../assets/images/hrastovo.png";

import Lets from "../../components/Lets/Lets";

import "../About/about.scss";

import { FaPlay } from "react-icons/fa";
import InfoContent from "../../components/InfoContent/InfoContent";
import ChooseOurs from "../../components/ChooseOurs/ChooseOurs";
import { Helmet } from "react-helmet";

export default function About() {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const openVideoModal = () => {
    setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  return (
    <div>
      <Helmet>
        <title>Rustic Wood Boards - Procitajte vise o nama</title>
      </Helmet>
      <div className="header-img-title">
        <div className="bg">
          <h2 className="title white">O nama</h2>
        </div>
        <img src={galleryHeader} alt="Header Image_" />
      </div>
      <InfoContent />
      <div className="about-content wrapper">
        <div className="text">
          <p>
            Bavimo se proizvodnjom unikatnih daski za serviranje, sečenje,
            stolova i natpisa za vaše vikendice. Svi naši proizvodi su izradjeni
            od posebno obradjenih komada drveta jer nam je u cilju da do
            izražaja dodje punoća, boja i teksture.
            <br />
            <br />
            Budući vlasnici naših rustic proizvoda imaće priliku da sami izaberu
            svrhu svoje daske. Možete ih koristiti za serviranje, sečenje ili
            kao dodatak vašem enterijeru.
            <br />
            <br />
            Zajedno sa nama učestvujete u stvaranju svoje savrsene daske.
          </p>
        </div>
        <div className="video">
          <img src={aboutContent} alt="" />
          <FaPlay className="play-icon" onClick={openVideoModal} />
        </div>

        {showVideoModal &&
          <div className="modal">
            <div className="modal-content" style={{ width: 500 }}>
              <span className="close" onClick={closeVideoModal}>
                &times;
              </span>
              <video controls className="video-player">
                <source src={videoFile} type="video/mp4" />
              </video>
            </div>
          </div>}
      </div>

      <ChooseOurs />

      <div className="wrapper">
        <p>
          Vrsta drveta koje koristimo za obradu i realizaciju unikatnih dasaka
        </p>
        <div className="wood">
          <div data-aos="zoom-in">
            <div className="wood-bg">
              <span>Orahovo drvo</span>
            </div>
            <img src={orahovo} alt="Orahovo" />
          </div>
          <div data-aos="zoom-in">
            <div className="wood-bg">
              <span>Hrastovo drvo</span>
            </div>
            <img src={hrastovo} alt="Hrastovo" />
          </div>
          <div data-aos="zoom-in">
            <div className="wood-bg">
              <span>Bagremovo drvo</span>
            </div>
            <img src={bagremovo} alt="Bagremovo" />
          </div>
        </div>
      </div>

      <Lets />
    </div>
  );
}
