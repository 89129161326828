import React from "react";
import lets from "../../assets/images/about-ret.jpeg";
import "../Lets/lets.scss";

export default function Lets() {
  return (
    <div>
      <div className="lets">
        <div className="text">
          <h3>
            Obezbedite najkvalitetniji rad <br /> to ispunjava vaša očekivanja.
          </h3>

          <a className="btn white" href="/kontakt">
            kontakt
          </a>
        </div>
        <img src={lets} alt="" />
      </div>
    </div>
  );
}
