import React, { useState } from "react";
import "./vertical-tabs.scss";

const VerticalTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(index);
  };

  return (
    <div className="wrapper">
      <h2 className="title">Naša ponuda</h2>
      <div className="vertical-tabs">
        <div className="tabs">
          <div
            className={`tab ${activeTab === 0 ? "active" : ""}`}
            onClick={() => handleTabClick(0)}
          >
            Daske za meze
          </div>
          <div
            className={`tab ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Daske za rezanje
          </div>
          <div
            className={`tab ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            Daske za serviranje
          </div>
        </div>
        <div className="content">
          {activeTab === 0 &&
            <div>
              Idealne za svaku priliku, naše daske za meze savršene su za
              posluživanje raznovrsnih zalogaja, od sireva i suvomesnatih
              proizvoda do voća i povrća. Svaka daska je ručno izrađena i dolazi
              u različitim veličinama i oblicima kako bi zadovoljila sve vaše
              potrebe.
            </div>}
          {activeTab === 1 &&
            <div>
              Naše daske za rezanje napravljene su od visokokvalitetnih
              materijala i služiće vas dugi niz godina. Savršeno balansirane,
              ove daske omogućavaju precizno i sigurno sečenje, čineći svaku
              pripremu hrane jednostavnom i prijatnom.
            </div>}
          {activeTab === 2 &&
            <div>
              Dodajte eleganciju svom stolu s našim daskama za serviranje. Bilo
              da organizujete porodičnu večeru ili svečani događaj, naše daske
              će vaše kulinarske kreacije predstaviti na najbolji mogući način.
            </div>}
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
