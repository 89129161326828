import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './page/Home/Home';
import Gallery from "./page/Gallery/Gallery";
import Contact from './page/Contact/Contact';
import About from './page/About/About';

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

    useEffect(() => {
      AOS.init({ duration: 1000 });
    }, []);
  
  return <div className={`App ${isVisible ? "visible" : ""}`}>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/galerija" element={<Gallery />} />
          <Route path="/o-nama" element={<About />} />
          <Route path="/kontakt" element={<Contact />} />
        </Routes>

        <Footer />
      </Router>
    </div>;
}

export default App;
