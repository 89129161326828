import React, { useState } from "react";
import "../Navbar/navbar.scss";
import logo from "../../assets/images/logoWood.png";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="navbar">
      <header>
        <div className="wrapper">
          <div className="call-info">
            <span>
              <a href="tel:+387 66 016 103">+387 66 016 103</a>
            </span>
            <span>
              <a href="mailto:kontakt@rusticwoodboards.com">
                kontakt@rusticwoodboards.com
              </a>
            </span>
          </div>
          <div className="network">
            <a
              href="https://www.instagram.com/rusticwoodboards/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61552762302835&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </div>
        </div>
      </header>
      <nav>
        <div className="wrapper">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Rustic Wood Boards Logo" />
            </a>
          </div>
          <ul className={!openMenu ? "" : " active"}>
            <li>
              <a href="/">Pocetna</a>
            </li>
            <li>
              <a href="/galerija">Galerija</a>
            </li>
            <li>
              <a href="/o-nama">O nama</a>
            </li>
            <li>
              <a href="/kontakt">Kontakt</a>
            </li>
          </ul>

          <div
            onClick={() => {
              setOpenMenu(!openMenu);
              setIsOpen(!isOpen);
            }}
            className={`menu ${isOpen ? "open " : ""}`}
          >
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </div>
        </div>
      </nav>
    </div>
  );
}
