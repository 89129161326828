import React, { useState } from "react";
import "../Contact/contact.scss";
import galleryHeader from "../../assets/images/contact.jpeg";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const newErrors = {
      name: formData.name === "",
      email: formData.email === "" || !validateEmail(formData.email),
      message: formData.message === ""
    };

    setErrors(newErrors);

    const formIsValid = !Object.values(newErrors).includes(true);

    if (formIsValid) {
      setIsLoading(true);

      const emailTemplate = `
        <html>
          <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: #333333;
            }

            * {
              box-sizing: border-box;
            }

            .container {
              width: 100%;
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
              background-color: #f7f7f7;
            }

            p {
              font-size: 16px;
              margin-top: 0;

            }
            b {
              font-size: 18px;
            }

            a {
              color: #167ade;
              text-decoration: underline;
            }

    
            .section {
              margin-bottom: 40px;
              margin-top: 20px;
            }

            .header {
              background-color: #ffffff;
              padding: 20px;
              text-align: center;
              display: flex;
              justify-content: space-between;
            }

            .header ul {
              padding-inline-start: 0;
            }

            .header ul li {
              list-style: none;
              text-align: start;
            }

            .logo {
              width: 30%;
            }

            @media only screen and (max-width: 600px) {
              .container {
                padding: 10px;
                width: 100%;
              }
            }
      
            .works img {
              width: 100%;
              border-radius: 20px;
            }
            .works a {
              background: #ecdbc1;
              padding: 10px;
              color: #000;
              display: inline-block;
              text-decoration: none;
              text-align: center;
              border-radius: 20px;
              margin-top: 20px;
              width: 100%;
            }

          </style>
          </head>

            <div class="container">
            <div class="header">
              <img src='https://www.rusticwoodboards.com/static/media/logoWood.0d79c8da87aa0015e42a.png' alt="Logo" class="logo">
              <ul>
                <li>Telefon: <a href="tel:+387 66 016 103" target="_blank">+387 66 016 103</a></li>
                <li>Email:   <a href="mailto:kontakt@rusticwoodboards.com" target="_blank">kontakt@rusticwoodboards.com</a></li>
                <li>Lokacija: <a href="https://www.google.com/maps/place/Zvornik,+Bosnia+and+Herzegovina/@44.3817354,19.0810739,15z/data=!3m1!4b1!4m6!3m5!1s0x47596903360826d9:0x918cad5bc69bb121!8m2!3d44.3865457!4d19.1048105!16zL20vMDVxazhk?entry=ttu" target="_blank">Zvornik</a></li>
              </ul>
            </div>

          
            <div class="section">
              <b>Ime i prezime:</b> <p>${formData.name}</p>
              <b>Email:</b> <p>${formData.email}</p>
              <b>Poruka:</b> <p>${formData.message}</p>
            </div>

              <div class="works">
              <h3 style="text-align: center;">Pogledajte naše radove</h3>
              <img src="https://www.rusticwoodboards.com/static/media/about.659da8f797be57ba84aa.jpeg" alt="Works">
              <a href="https://www.rusticwoodboards.com/galerija">Naši radovi</a>
            </div>
          </div>
        </html>
      `;

      try {
        await fetch("/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: emailTemplate
          })
        });

        setTimeout(() => {
          setIsLoading(false);
          setShowModal(true);
          setFormData({ name: "", email: "", message: "" });
          setErrors({ name: false, email: false, message: false });
        }, 2000);
      } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Helmet>
        <title>Rustic Wood Boards - Kontaktirajte nas</title>
      </Helmet>
      <div className="header-img-title">
        <div className="bg">
          <h2 className="title white">Kontakt</h2>
        </div>
        <img src={galleryHeader} alt="Header Image_" />
      </div>

      <div className="form wrapper">
        <div className="form-info">
          <div className="item1">
            <FaLocationDot />
            <p>Zvornik, Bosna i Hercegovina</p>
          </div>
          <div className="item2">
            <FaPhoneVolume />
            <a href="tel:+387 66 016 103">+387 66 016 103</a>
          </div>
          <div className="item3">
            <MdEmail />
            <a href="mailto:kontakt@rusticwoodboards.com">
              kontakt@rusticwoodboards.com
            </a>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Vaše ime</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Unesite vaše ime"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="error">Obavezno polje</p>}
          </div>

          <div>
            <label htmlFor="email">Vaš email</label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Unesite vaš email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email &&
              <p className="error">
                {formData.email === ""
                  ? "Obavezno polje"
                  : "Unesite validan email"}
              </p>}
          </div>

          <div>
            <label htmlFor="message">Vaša poruka</label>
            <textarea
              id="message"
              name="message"
              placeholder="Unesite vašu poruku"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <p className="error">Obavezno polje</p>}
          </div>

          <button className="btn" type="submit" disabled={isLoading}>
            {isLoading ? "Slanje..." : "Pošaljite poruku"}
          </button>
        </form>
      </div>

      {showModal &&
        <div className="modal">
          <div className="modal-content" style={{ background: "#fff" }}>
            <p>Poruka je uspešno poslata!</p>
            <button onClick={closeModal}>Zatvori</button>
          </div>
        </div>}
    </div>
  );
}
