import React from "react";
import "../Home/home.scss";

import banner from "../../assets/images/banner.jpeg";
import img1 from "../../assets/images/gallery1.png";
import img2 from "../../assets/images/gallery2.jpg";
import img3 from "../../assets/images/gallery3.png";
import heart from "../../assets/images/heart.jpeg";
import daska from "../../assets/images/daska.jpeg";
import Lets from "../../components/Lets/Lets";

import InfoContent from "../../components/InfoContent/InfoContent";
import VerticalTabs from "../../components/Vertical-tabs/VerticalTabs";
import ChooseOurs from "../../components/ChooseOurs/ChooseOurs";

export default function Home() {
  return (
    <div>
      <div className="home-banner">
        <div className="banner-text">
          <div data-aos="fade-up">
            <h1>Unikatne Daske za Vaš Dom i Prostor</h1>
            <p>
              U Rustic Wood Boards, verujemo da je svaki obrok prilika za
              stvaranje posebnih trenutaka. Naše daske za meze, rezanje i
              serviranje dizajnirane su s pažnjom i ljubavlju prema detaljima
              kako bi vašem stolu dodali eleganciju i funkcionalnost.
            </p>

            <a className="btn white" href="/kontakt">
              poruči svoju dasku
            </a>
          </div>
        </div>
        <img src={banner} alt="" />
      </div>

      <InfoContent />

      <VerticalTabs />

      <ChooseOurs />

      <div className="gallery wrapper">
        <h2 data-aos="fade-up" className="title">
          Galerija
        </h2>
        <p data-aos="fade-up">
          Prošetajte kroz našu galeriju i doživite lepotu i orginalnost naših
          unikatnih dasaka. Svaki komad je pažljivo stvoren da oplemeni vaš
          prostor.
        </p>

        <div className="row">
          <div className="row1">
            <div className="row-img" data-aos="fade-up">
              <img src={img1} alt="" />
            </div>
            <div
              className="row-img"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <img src={img2} alt="" />
            </div>
          </div>
          <div className="row2" data-aos="fade-up" data-aos-duration="2000">
            <div className="row-img" style={{ marginBottom: 50 }}>
              <img src={img3} alt="" />
            </div>

            <a className="btn" href="/galerija">
              pogledajte našu galeriju
            </a>
          </div>
        </div>
      </div>

      <div className="about wrapper">
        <h2 className="title">O nama</h2>

        <div className="row">
          <div data-aos="fade-right">
            <img src={heart} alt="" />
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <p style={{ marginBottom: 50 }}>
              Budući vlasnici naših rustic proizvoda imaće priliku da sami
              izaberu svrhu svoje daske. Možete ih koristiti za serviranje,
              sečenje ili kao dodatak vašem enterijeru.
            </p>
            <a className="btn" href="/o-nama">
              pročitajte o nama
            </a>
            <img
              style={{ marginTop: 50, height: 370, objectFit: "cover" }}
              src={daska}
              alt=""
            />
          </div>
        </div>
      </div>

      <Lets />
    </div>
  );
}
