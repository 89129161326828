import React from "react";
import "../Footer/footer.scss";
import logo from "../../assets/images/logoWoodBg.jpg";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  return (
    <div className="footer">
      <div className="foooter-box wrapper">
        <div>
          <img src={logo} alt="" />
        </div>
        <div>
          <h3>mapa sajta</h3>
          <ul>
            <li>
              <a href="/">Pocetna</a>
            </li>
            <li>
              <a href="/galerija">Galerija</a>
            </li>
            <li>
              <a href="/o-nama">O nama</a>
            </li>
            <li>
              <a href="/kontakt">Kontakt</a>
            </li>
          </ul>
        </div>
        <div>
          <h3>kontakt</h3>
          <ul>
            <li>
              <a href="/location">Zvornik, Bosna i Hercegovina</a>
            </li>
            <li>
              <a href="mailto:kontakt@rusticwoodboards.com">
                kontakt@rusticwoodboards.com
              </a>
            </li>
            <li>
              <a href="tel:+38766016103">+387 66 016 103</a>
            </li>
            <li>
              <a href="tel:+381654482592">+381 65 448 2592 (WhatsApp, Viber)</a>
            </li>
          </ul>
        </div>
        <div>
          <h3>mreze</h3>
          <ul style={{ display: "flex", gap: 20 }}>
            <li>
              <a
                href="https://www.instagram.com/rusticwoodboards/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram style={{ fontSize: 30 }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61552762302835&mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF style={{ fontSize: 30 }} />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright">
        Rusticwoodboards © 2023 | by{" "}
        <a
          style={{ textDecoration: "none", color: "var(--brend-color)" }}
          href="https://www.gold-digital.rs"
        >
          Gold Digital
        </a>
      </div>
    </div>
  );
}
