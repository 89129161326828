import React from "react";

export default function ChooseOurs() {
  return (
    <div className="wrapper">
      <h2 className="title">Zašto odabrati nas?</h2>
      <div className="info-content">
        <div data-aos="zoom-in">
          <span>Visok kvalitet</span>
          <p>
            Koristimo samo najkvalitetnije materijale kako bismo osigurali
            dugotrajnost i izdržljivost naših proizvoda.
          </p>
        </div>
        <div data-aos="zoom-in">
          <span>Ručno izrađeno</span>
          <p>
            Svaka daska je pažljivo ručno izrađena, što svaku čini jedinstvenom.
          </p>
        </div>
        <div data-aos="zoom-in">
          <span>Ekološki prihvatljivo</span>
          <p>
            Naši proizvodi su napravljeni od održivih materijala, brinemo o
            prirodi kao i o vašem stolu.
          </p>
        </div>
        <div data-aos="zoom-in">
          <span>Zadovoljstvo kupaca</span>
          <p>
            Naš prioritet je vaše zadovoljstvo. Ponosni smo na našu uslugu i
            trudimo se da svaki kupac bude zadovoljan svojim izborom.
          </p>
        </div>
      </div>
    </div>
  );
}
