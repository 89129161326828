import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "../Gallery/gallery.scss";
import img1 from "../../assets/images/gallery1.png";
import img2 from "../../assets/images/gallery2.jpg";
import img3 from "../../assets/images/gallery3.png";
import img4 from "../../assets/images/daska.jpeg";
import img5 from "../../assets/images//banner.jpeg";
import img6 from "../../assets/images/heart.jpeg";
import img7 from "../../assets/images/img1.png";
import img8 from "../../assets/images/img2.jpg";
import img9 from "../../assets/images/img3.jpg";
import img10 from "../../assets/images/img4.jpg";
import img11 from "../../assets/images/img5.jpg";
import img12 from "../../assets/images/img6.jpg";
import img13 from "../../assets/images/about.jpeg";
import galleryHeader from "../../assets/images/contact.jpeg";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13
];

export default function Gallery() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handlePrev = () => {
    setSelectedImageIndex(
      prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1)
    );
  };

  const handleNext = () => {
    setSelectedImageIndex(
      prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1)
    );
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handleKeyDown = event => {
    if (selectedImageIndex !== null) {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "Escape") {
        handleClose();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });
  return (
    <div>
      <Helmet>
        <title>Rustic Wood Boards - Galerija proizvoda</title>
      </Helmet>
      <div className="header-img-title">
        <div className="bg">
          <h2 className="title white">Galerija</h2>
        </div>
        <img src={galleryHeader} alt="Header Image_" />
      </div>
      <div className="gallery-container wrapper">
        {images.map((src, index) =>
          <div
            className="gallery-item"
            key={index}
            onClick={() => setSelectedImageIndex(index)}
          >
            <img src={src} alt="Slike galerije" />
          </div>
        )}
      </div>
      {selectedImageIndex !== null &&
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img
            className="modal-content"
            src={images[selectedImageIndex]}
            alt="Selected"
          />
          <span className="prev" onClick={handlePrev}>
            &#10094;
          </span>
          <span className="next" onClick={handleNext}>
            &#10095;
          </span>
        </div>}
    </div>
  );
}
